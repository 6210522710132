import React, { useState } from 'react';
import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

export default function ExpansionList(props) {
  const [expanded, toggleExpansion] = useState(false);
  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={() => toggleExpansion(!expanded)}
      {...props}
    >
      <ExpansionPanelSummary
        expandIcon={<FontAwesomeIcon icon={faAngleDown} size="2xs" />}
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{
          display: 'flex',
          alignItems: 'start',
        }}
      >
        {!expanded ? props.title : props.children}
      </ExpansionPanelSummary>
    </ExpansionPanel>
  );
}
