import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { CspDetailItem } from '../../Csp/components';
import { Brand } from '../types';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { useProfile } from '../../../../hooks';

interface BrandContactDetailsProps {
  data?: Brand;
}

const BrandContactDetails: FC<BrandContactDetailsProps> = ({ data }) => {
  const { loading: loadingProfile, profile } = useProfile();
  const getBusinessContactFullName = (): string => {
    if (data?.businessContactFirstName || data?.businessContactLastName) {
      return `${data?.businessContactFirstName || ''} ${
        data?.businessContactLastName || ''
      }`.trim();
    }
    return '';
  };

  const isDpaSigned = !loadingProfile && !!profile?.dpaSigned;

  const renderRedactableField = (
    field?: string | null
  ): string | undefined | null => {
    return isDpaSigned ? field : 'Redacted due to PII';
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <CspDetailItem
          title="Name"
          value={renderRedactableField(getBusinessContactFullName())}
        />
        <CspDetailItem
          title="Email"
          value={renderRedactableField(data?.businessContactEmail)}
        />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem
          title="Title"
          value={renderRedactableField(data?.businessContactTitle)}
        />
        <CspDetailItem
          title="Email Verified Date"
          value={
            data?.businessContactEmailVerifiedDate
              ? convertTimeWithTimezone(
                  data?.businessContactEmailVerifiedDate,
                  undefined,
                  'MM/DD/YYYY'
                )
              : null
          }
        />
      </Grid>
    </Grid>
  );
};

export default BrandContactDetails;
