import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { S3_ASSETS_PATH } from '../../../../constants';
import {
  US_DATE_FORMAT,
  convertTimeWithTimezone,
} from '../../../../utils/time';
import { Box } from 'portal-commons';
import { getStatusIcon } from '../../../../utils/statusIcon';

function SuspensionCampaignRow(props) {
  const { campaign } = props;
  return (
    <TableRow
      data-testid="suspensionCampaignRow"
      className="link"
      onClick={() =>
        props.history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 125 }}
        data-testid="suspensionCampaignRowUid"
      >
        <span>{campaign.uid ? campaign.uid : campaign.id}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 105 }}
        data-testid="suspensionCampaignRowUsecase"
      >
        {campaign.usecase}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 135 }}
        data-testid="suspensionCampaignRowCreateDate"
      >
        {convertTimeWithTimezone(
          campaign.createDate,
          undefined,
          US_DATE_FORMAT
        )}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, paddingTop: 0, paddingBottom: 0 }}
        data-testid="suspensionCampaignRowOperationStatus"
      >
        <div className="mno-operational-status">
          {campaign.operationStatus ? (
            <React.Fragment>
              <Box flexDirection="row" alignItems="center">
                <Box margin={{ right: 'xs' }}>
                  {getStatusIcon(campaign.operationStatus)}
                </Box>
                <span>{campaign.operationStatus}</span>
              </Box>
            </React.Fragment>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
SuspensionCampaignRow.propTypes = {
  campaign: PropTypes.object,
};
export default withRouter(SuspensionCampaignRow);
