import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from 'portal-commons';
import { getStatusIcon } from '../../../../utils/statusIcon';

function CampaignListingRow(props) {
  const { campaign } = props;
  return (
    <TableRow
      data-testid="campaignsListingRow"
      className="link"
      onClick={() =>
        props.history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 125 }}
        data-testid="campaignsListingRowUid"
      >
        <span>{campaign.uid ? campaign.uid : campaign.id}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 105 }}
        data-testid="campaignsListingRowBrandUid"
      >
        {campaign.brandUid}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, maxWidth: 220, overflowWrap: 'break-word' }}
        data-testid="campaignsListingRowBrandName"
      >
        {campaign.brandName
          ? campaign.brandName
          : campaign.usecase == 'SOLE_PROPRIETOR'
          ? 'Redacted due to PII'
          : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 105 }}
        data-testid="campaignsListingRowUsecase"
      >
        {campaign.usecase}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 135 }}
        data-testid="campaignsListingRowCreateDate"
      >
        {campaign.createDate}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, paddingTop: 0, paddingBottom: 0 }}
        data-testid="campaignsListingRowMnoStatus"
      >
        <div className="mno-operational-status">
          {campaign.mnoStatus ? (
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                {getStatusIcon(campaign.mnoStatus.toLowerCase())}
              </Box>
              <span>
                {campaign.mnoStatus.toLowerCase() === 'approved'
                  ? 'REGISTERED'
                  : campaign.mnoStatus}
              </span>
            </Box>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
CampaignListingRow.propTypes = {
  campaign: PropTypes.object,
};
export default withRouter(CampaignListingRow);
