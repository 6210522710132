import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { convertTimeWithTimezone } from '../../../../../utils/time';
import { ExtVettingRecord } from './type';
import ScoreTooltip from './ScoreTooltip';
import { Box } from 'portal-commons';

interface Props {
  record: ExtVettingRecord;
}

const VettingDetailsListingRow: React.FC<Props> = ({ record }) => {
  const lowerCase = (str: string) => str.toLowerCase();

  const displayVettingDate = () => {
    const { vettingStatus, vettedDate } = record;
    switch (lowerCase(vettingStatus)) {
      case 'active':
        if (vettedDate) {
          return convertTimeWithTimezone(vettedDate);
        } else {
          return '--';
        }
      case 'expired':
        return 'Expired';
      case 'contact_vendor':
        return 'Contact Vendor';
      default:
        return 'N/A';
    }
  };

  const displayVettingOutcome = () => {
    const { vettingStatus, score, evpUid } = record;
    switch (lowerCase(vettingStatus)) {
      case 'active':
        if (score) {
          return `${score}`;
        } else if (evpUid === 'CV') {
          return 'Verified';
        } else {
          return '--';
        }
      case 'expired':
        return 'Expired';
      case 'contact_vendor':
        return 'Contact Vendor';
      default:
        return 'N/A';
    }
  };

  return (
    <TableRow>
      <TableCell style={{ width: '20%' }}>
        <span>{record.vettingClass}</span>
      </TableCell>
      <TableCell style={{ width: '20%' }}>{record.evpUid}</TableCell>
      <TableCell style={{ width: '15%' }}>{displayVettingDate()}</TableCell>
      <TableCell
        className="outcome"
        style={{
          width: '15%',
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <Box alignItems="center" flexDirection="row">
          {displayVettingOutcome()}
          <ScoreTooltip data={record} />
        </Box>
      </TableCell>
      <TableCell style={{ width: '30%' }}>{record.vettingStatus}</TableCell>
    </TableRow>
  );
};

export default VettingDetailsListingRow;
