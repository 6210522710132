import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { AppLayout } from 'portal-commons';
import { Header, SideNavigation } from '../shared_elements';
import { withRouter, Redirect } from 'react-router-dom';
import { toastFlashMessage } from '../utils';

async function checkUser() {
  if (this.props.authState.isAuthenticated && !this.state.userInfo) {
    const userInfo = await this.props.oktaAuth.getUser();
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    this.setState({ userInfo });
  }
}

const Footer = () => (
  <div className="footer-hoc-block">
    <div className="text-center">
      <p>
        Confidentiality Notice: The information accessed through The Campaign
        Registry MNO Portal is intended for use by authorized individuals only.
        Any unauthorized access or use of this information is prohibited. By
        using The Campaign Registry MNO Portal, you agree to keep all accessed
        information confidential in accordance with the{' '}
        <a
          href="https://www.campaignregistry.com/TCR-T&Cs.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Use
        </a>
        .
      </p>
    </div>
  </div>
);

function HeaderHoc(ComposedComponent, extraInfo) {
  class HeaderHoc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        userInfo: '',
        userDetail: localStorage.getItem('okta-token-storage')
          ? JSON.parse(localStorage.getItem('okta-token-storage'))
          : {},
      };
      this.checkUser = checkUser.bind(this);
    }
    toggleDrawer = (flag) => {
      this.setState({
        open: flag,
      });
    };
    componentDidMount() {
      this.checkUser();
      let userDetail = localStorage.getItem('okta-token-storage')
        ? JSON.parse(localStorage.getItem('okta-token-storage'))
        : {};
      if (typeof userDetail === 'object' && userDetail !== null) {
        if (!(userDetail.idToken && userDetail.accessToken)) {
          // toastFlashMessage('Please login again', 'success')
          setTimeout(() => {
            localStorage.clear();
            this.props.history.push('/login');
          }, 1000);
        }
      } else {
        // toastFlashMessage('Please login again', 'success')
        setTimeout(() => {
          localStorage.clear();
          this.props.history.push('/login');
        }, 1000);
      }
    }
    render() {
      const pathName = this.props.location.pathname;
      const { userDetail } = this.state;
      if (!(userDetail.idToken && userDetail.accessToken)) {
        toastFlashMessage('Please login again', 'success');
        localStorage.clear();
        return <Redirect to="/login" />;
      } else {
        return (
          <AppLayout
            authenticated
            topBar={
              <Header
                authenticated
                title={extraInfo ? extraInfo.title : ''}
                path={pathName}
              />
            }
            sideNavigation={<SideNavigation path={pathName} />}
            footer={<Footer />}
          >
            <ComposedComponent {...this.props} />
          </AppLayout>
        );
      }
    }
  }
  return withOktaAuth(withRouter(HeaderHoc));
}
export default HeaderHoc;
