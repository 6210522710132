import React from 'react';
import BrandListingRow from '../components/BrandListingRow';
import { Grid, Container } from '@material-ui/core';
import { isEqual } from 'lodash';
import queryString from 'query-string';
import { globalGetService } from '../../../../utils/globalApiServices';
import { Loader, FilterToggleButton } from '../../../../shared_elements';
import { findAllIdentityStatuses } from '../apiServices';
import '../assets/brands-listing-module.scss';
import { ENTITY_TYPES } from '../../../../constants';
import {
  BoxV2 as Box,
  Flex,
  Table,
  FilterType,
  TableFilter,
} from 'portal-commons';
import { withFilters } from '../../../../hocs';
import { fieldValidation } from '../../../../utils/validator';
import { fetchBrands, fetchCspSuggestions } from '../apis';

const FILTER_CONFIG = {
  ein: {
    type: FilterType.Text,
    label: 'EIN/Tax number',
    placeholder: 'Enter EIN/Tax number',
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter Brand ID',
  },
  universalEin: {
    type: FilterType.Text,
    label: 'Universal EIN',
    placeholder: 'Enter Universal EIN',
    error: false,
    helperText: '',
  },
  displayName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter Brand Name',
    suggestions: [],
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Enter CSP name',
    suggestions: [],
  },
  entityType: {
    type: FilterType.Dropdown,
    options: ENTITY_TYPES,
    label: 'Entity Type',
    width: 200,
  },
  identityStatus: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Identity Status',
    width: 200,
  },
};

const FilterErrorCode = {
  universalEin: {
    0: '',
    5: 'Invalid input',
  },
  universalEinObj: {
    xssProtection: true,
  },
};

const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'createDate',
  ascendingOrder: false,
};

const headRows = [
  { id: 'uid', label: 'BRAND ID', sortable: true },
  { id: 'displayName', label: 'BRAND NAME', sortable: true },
  { id: 'cspName', label: 'CSP NAME', sortable: true },
  { id: 'entityType', label: 'ENTITY TYPE', sortable: true },
  { id: 'universalEin', label: 'UNIVERSAL EIN', sortable: false },
  { id: 'activeCampaignCount', label: 'N. OF CAMPAIGNS', sortable: false },
];

const fetchBrandSuggestions = (query = {}) =>
  globalGetService('mno/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.brandName) ?? [];
      }
      return [];
    }
  );

const fetchCspNameSuggestions = async (query = {}) => {
  const suggestions = await fetchCspSuggestions(query);
  return suggestions.map((suggestion) => suggestion.cspName);
};

class BrandsListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: true,
      loader: true,
      additionalFilters: DEFAULT_ADDITIONAL_FILTERS,
      isFilterVisible: false,
    };
  }

  fetchFilterOptions = async () => {
    const { setOptions } = this.props.filter;
    const identityStatuses = await findAllIdentityStatuses();
    setOptions(
      'identityStatus',
      identityStatuses.map((status) => ({ label: status, value: status }))
    );
  };

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField,
        ascendingOrder: JSON.parse(queries.ascendingOrder),
      };
    }

    this.setState({ additionalFilters: updatedFilter });

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  componentDidMount() {
    this.fetchFilterOptions();
    if (this.props.location.search) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchBrandsList(params);
    } else {
      this.fetchBrandsList({ ...this.state.additionalFilters });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchBrandsList(params);
    }
  }

  fetchBrandsList = async (queries = {}) => {
    this.setState({ tableLoader: true });
    const additionalQueries = {};
    if (queries.cspName) {
      const suggestions = await fetchCspSuggestions({
        prefix: queries.cspName,
      });
      if (suggestions.length > 0) {
        additionalQueries.cspUid = suggestions[0].cspUid;
      }
    }
    const brandInfo = await fetchBrands({ ...queries, ...additionalQueries });
    if (brandInfo) {
      this.setState((prevState) => ({
        ...prevState,
        brandInfo,
        loader: false,
      }));
      if (
        brandInfo.records.length === 0 &&
        brandInfo.totalRecords > 0 &&
        brandInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(brandInfo.totalRecords / 10);
        this.setState({ loader: true });
        this.writeQueryString({ ...queries, page: lastPageNo });
      }
    }
    this.setState({ tableLoader: false });
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page: newPage,
    });
  };

  handleSorting = (sortField) => {
    const { brandInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));

    if (brandInfo.totalRecords) {
      this.writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: brandInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  resetFilterConfigsError = (newValues) => {
    const { configs, setConfig, candidateFilters } = this.props.filter;
    Object.keys(newValues).forEach((key) => {
      if (configs[key]?.error && newValues[key] !== candidateFilters[key]) {
        setConfig(key, { ...configs[key], error: false, helperText: '' });
      }
    });
  };

  validateFilters = (values) => {
    const { configs, setConfig } = this.props.filter;
    let isValid = Object.keys(values).every((key) => !configs[key].error);
    Object.keys(values).forEach((key) => {
      if (FilterErrorCode[key]) {
        const code = fieldValidation({
          ...FilterErrorCode[`${key}Obj`],
          fieldval: values[key],
        });
        if (code !== 0) {
          isValid = false;
          setConfig(key, {
            ...configs[key],
            error: true,
            helperText: FilterErrorCode[key][code],
          });
        }
      }
    });

    return isValid;
  };

  handleCandidateFiltersChange = (values = {}) => {
    this.resetFilterConfigsError(values);
    this.props.filter.handleEdit(values);
  };

  handleAppliedFiltersChange = (values = {}) => {
    if (!this.validateFilters(values)) {
      return;
    }
    if (Object.keys(values).length === 0) {
      this.resetFilterConfigsError(this.props.filter.configs);
    }
    this.props.filter.handleApply(values);
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  render() {
    const {
      brandInfo,
      tableLoader,
      loader,
      additionalFilters,
      isFilterVisible,
    } = this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
    } = this.props.filter;

    return (
      <Box sx={{ width: '100%' }}>
        <section className="brands-listing-section page-content">
          {loader ? (
            <Loader />
          ) : (
            <Container
              maxWidth={false}
              className="brands-listing-container"
              data-testid="brandsListing"
            >
              <Box>
                <div className="title-block">
                  <h2>
                    {!loader ? `${brandInfo.totalRecords} Brands` : 'Brands'}
                  </h2>
                </div>
              </Box>
              <Flex
                sx={{
                  flexDirection: 'column',
                  marginTop: 's',
                  marginBottom: 's',
                }}
              >
                <Box>
                  <FilterToggleButton
                    visible={isFilterVisible}
                    onClick={this.toggleFilterVisibility}
                  />
                </Box>
                {isFilterVisible && (
                  <TableFilter
                    portal="mno"
                    configs={filterConfigs}
                    candidateValues={candidateFilters}
                    appliedValues={appliedFilters}
                    onCandidateValuesChange={this.handleCandidateFiltersChange}
                    onAppliedValuesChange={this.handleAppliedFiltersChange}
                    className="filters"
                    data-testid="brands"
                  />
                )}
              </Flex>
              <Grid container justifyContent="center" spacing={0}>
                <Table
                  testId="brandsTable"
                  className="brands-table"
                  records={{ total: brandInfo.totalRecords }}
                  loading={tableLoader}
                  headRows={headRows}
                  emptyState="no brands to view"
                  data={brandInfo.records}
                  rowKey="uid"
                  renderRow={(data) => {
                    return <BrandListingRow key={data.uid} brand={data} />;
                  }}
                  handleChangePage={this.handleChangePage}
                  createSortHandler={this.handleSorting}
                  filter={additionalFilters}
                  pagination={{
                    count: Math.ceil(
                      brandInfo.totalRecords / brandInfo.recordsPerPage
                    ),
                    rowsPerPage: brandInfo.recordsPerPage,
                    page: brandInfo.page,
                    totalRecords: brandInfo.totalRecords,
                  }}
                />
              </Grid>
            </Container>
          )}
        </section>
      </Box>
    );
  }
}

export default withFilters(BrandsListing, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      displayName: {
        minLength: 2,
        load: (value) => fetchBrandSuggestions({ prefix: value }),
      },
      cspName: {
        minLength: 2,
        load: (value) => fetchCspNameSuggestions({ prefix: value }),
      },
    },
  },
});
