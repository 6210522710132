import { globalGetService } from '../../../utils/globalApiServices';
import queryString from 'query-string';
import { convertTimeWithTimezone } from '../../../utils/time';

export function getEventsListApi(query = {}) {
  this.setState({ tableLoader: true });
  globalGetService('mno/event', query).then((response) => {
    this.setState({ tableLoader: false });
    if (response.status >= 200 && response.status < 300) {
      const eventInfo = response.data;
      eventInfo.records.forEach((event) => {
        event.createDate = convertTimeWithTimezone(event.createDate);
      });
      this.setState({ eventInfo, loader: false });
      if (
        eventInfo.records.length == 0 &&
        eventInfo.totalRecords > 0 &&
        eventInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(eventInfo.totalRecords / 10);
        this.setState({
          loader: true,
        });
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: true }
          )}`,
        });
        // this.getCampaignsListApi({...query, page: lastPageNo})
      }
    }
  });
}

export const getAllEventTypes = async () => {
  const response = await globalGetService('enums/eventTypes');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export function getBrandsSuggestionsApi(query = {}, key) {
  globalGetService('mno/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          filterObject: {
            ...prevState.filterObject,
            [key]: {
              ...prevState.filterObject[key],
              suggestions: response.data,
              suggestionLoader: false,
            },
          },
        }));
      }
    }
  );
}
export function getCspsSuggestionsApi(query = {}, key) {
  globalGetService('mno/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          filterObject: {
            ...prevState.filterObject,
            [key]: {
              ...prevState.filterObject[key],
              suggestions: response.data,
              suggestionLoader: false,
            },
          },
        }));
      }
    }
  );
}
