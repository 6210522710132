import { useState, useEffect } from 'react';
import { globalGetService } from '../utils/globalApiServices';
import { Profile } from '../applications/MnoPortal/Profile/types';

export const useProfile = () => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchProfile = async () => {
    setLoading(true);
    const response = await globalGetService('mno/profile');
    if (response.ok && response.data) {
      setProfile(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return {
    profile,
    loading,
  };
};
