import React, { useEffect, useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import ApiKeyActionDialog from './ApiKeyActionDialog';
import AddNewApiKeyDialog from './AddNewApiKeyDialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { globalPutService } from '../../../../utils/globalApiServices';
import { CustomSwitch, CustomTooltip } from '../../../../shared_elements';
import { toastFlashMessage } from '../../../../utils';
import { convertTimeWithTimezone } from '../../../../utils/time';

import { Box, Table } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faArrowRotateRight,
  faDna,
  faPen,
  faCopy,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';

const SCENE = {
  Edit: 'edit',
  Clone: 'clone',
  Rotate: 'rotate',
  Delete: 'delete',
};

export default function ApiKeyListingRow(props) {
  const { accountObj } = props;
  const [account, setAccount] = useState(accountObj ? accountObj : {});
  const [scene, setScene] = useState(null);

  const updateStatus = (status) => {
    const formdata = {
      enabled: status,
    };
    globalPutService(`mno/api/account/${account.id}`, formdata).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          toastFlashMessage(`API Key updated successfully`, 'success');
          setAccount(response.data);
        }
      }
    );
  };

  useEffect(() => {
    setAccount(accountObj);
  }, [accountObj]);

  return (
    <React.Fragment>
      <TableRow data-testid="apiKeyListingRow">
        <TableCell style={{ width: '33%' }}>
          {account.displayName ? account.displayName : '--'}
        </TableCell>
        <TableCell style={{ width: '8.3%' }}>
          <Box
            flexDirection="row"
            className="api-key-wrapper"
            data-testid="apiKeyListingRowApiKey"
            style={{ gap: '15px' }}
          >
            <CustomTooltip
              arrow
              title={
                <p>
                  API KEY <br /> {account.apiKey}
                </p>
              }
              placement="top"
            >
              <div className="img-wrapper view">
                <FontAwesomeIcon icon={faEye} />
              </div>
            </CustomTooltip>
            <CopyToClipboard
              text={account.apiKey}
              onCopy={() =>
                toastFlashMessage(
                  'API Key has been copied to your clipboard',
                  'success'
                )
              }
            >
              <CustomTooltip
                arrow
                placement="top"
                title={<div className="tooltip-content">Copy</div>}
              >
                <div className="img-wrapper copy">
                  <FontAwesomeIcon icon={faCopy} />
                </div>
              </CustomTooltip>
            </CopyToClipboard>
          </Box>
        </TableCell>
        <TableCell style={{ width: '8.3%' }}>
          <Box
            flexDirection="row"
            className="secret-wrapper"
            data-testid="apiKeyListingRowSecret"
            style={{ gap: '15px' }}
          >
            <CustomTooltip
              arrow
              title={
                <p>
                  SECRET <br />
                  {account.secret}
                </p>
              }
              placement="top"
            >
              <div className="img-wrapper view">
                <FontAwesomeIcon icon={faEye} />
              </div>
            </CustomTooltip>
            <CopyToClipboard
              text={account.secret}
              onCopy={() =>
                toastFlashMessage(
                  'Secret has been copied to your clipboard',
                  'success'
                )
              }
            >
              <CustomTooltip
                arrow
                placement="top"
                title={<div className="tooltip-content">Copy</div>}
              >
                <div className="img-wrapper copy">
                  <FontAwesomeIcon icon={faCopy} />
                </div>
              </CustomTooltip>
            </CopyToClipboard>
          </Box>
        </TableCell>
        <TableCell
          style={{ width: '25%' }}
          data-testid="apiKeyListingRowCreateDate"
        >
          {convertTimeWithTimezone(account.createDate)}
        </TableCell>
        <TableCell
          style={{ width: '8.3%', padding: '8px' }}
          data-testid="apiKeyListingRowCustomSwitch"
        >
          <CustomSwitch
            val={account.enabled ? true : false}
            handleChange={() => updateStatus(!account.enabled)}
          />
        </TableCell>
        <TableCell>
          <Box
            flexDirection="row"
            className="actions-wrapper"
            data-testid="apiKeyListingRowEditButton"
            style={{ gap: '17px' }}
          >
            <CustomTooltip
              arrow
              title={<div className="tooltip-content">Edit</div>}
              placement="top"
            >
              <div className="img-wrapper" onClick={() => setScene(SCENE.Edit)}>
                <FontAwesomeIcon icon={faPen} />
              </div>
            </CustomTooltip>
            <CustomTooltip
              arrow
              title={<div className="tooltip-content">Clone</div>}
              placement="top"
            >
              <div
                className="img-wrapper clone"
                onClick={() => setScene(SCENE.Clone)}
              >
                <FontAwesomeIcon icon={faDna} />
              </div>
            </CustomTooltip>
            <CustomTooltip
              arrow
              title={<div className="tooltip-content">Rotate</div>}
              placement="top"
            >
              <div
                className="img-wrapper"
                onClick={() => setScene(SCENE.Rotate)}
              >
                <FontAwesomeIcon icon={faArrowRotateRight} />
              </div>
            </CustomTooltip>
            <CustomTooltip
              arrow
              title={<div className="tooltip-content">Delete</div>}
              placement="top"
            >
              <div
                className="img-wrapper delete"
                onClick={() => setScene(SCENE.Delete)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </CustomTooltip>
          </Box>
        </TableCell>
      </TableRow>
      {!!scene && [SCENE.Clone, SCENE.Rotate, SCENE.Delete].includes(scene) ? (
        <ApiKeyActionDialog
          type={scene}
          handleClose={() => setScene(null)}
          account={account}
          fetchApiAccounts={props.fetchApiAccounts}
        />
      ) : null}
      <AddNewApiKeyDialog
        open={scene === SCENE.Edit}
        type={'edit'}
        handleClose={() => setScene(null)}
        account={account}
        setAccount={setAccount}
      />
    </React.Fragment>
  );
}
